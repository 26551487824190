<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      title="订单详情"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-notice-bar
        v-if="state.from == 'form'"
        left-icon="info-o"
        :text="
          state.tips
            ? state.tips
            : state.charging_msg
            ? state.charging_msg
            : '启动中, 请勿拔出充电枪...'
        "
      />
      <van-cell title="订单编号" :value="state.order_id" />
      <van-cell title="电站名称" :value="state.station_name" />
      <van-cell title="设备名称" :value="state.device_name" />
      <van-cell title="设备编码" :value="state.device_code" />
      <van-cell title="充电枪号" :value="gunName" />
      <van-cell title="充电状态" :value="chargingStatus" />
      <van-cell title="充电时长" :value="chargingTime" />
      <van-cell title="充电电量" :value="totalPower" />
      <van-cell title="订单费用" :value="totalAmount" />
      <van-cell title="订单创建" :value="createdAt" />
      <van-cell
        v-if="state.order_status == 3"
        title="开始充电"
        :value="startedAt"
      />
      <van-cell
        v-if="state.order_status == 3"
        title="结束充电"
        :value="stoppedAt"
      />
      <div
        v-if="
          !state.disableStopCharging &&
          state.order_status == 1 &&
          state.charging_status == 2
        "
        class="py-4 action-button-area text-center"
      >
        <van-button type="danger" @click="stopCharging">结束充电</van-button>
      </div>
      <div
        v-if="
          state.showDrawGunTips ||
          (state.order_status == 2 && state.end_code === null)
        "
        class="py-4 action-button-area text-center"
      >
        <van-button type="primary" @click="loadForm" disabled
          >充电结束，请拔出充电枪</van-button
        >
      </div>
      <div
        v-if="
          state.order_status == 0 &&
          (state.charging_status == 0 || state.charging_status == 1)
        "
        class="py-4 action-button-area text-center text-success"
      >
        正在启动, 请稍后...
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Dialog, Field, NoticeBar, Toast } from "vant";
import { stopPowerRecharge } from "@/api/device.service";
import { getChargingOrderDetail } from "@/api/account.service";
import Moment from "moment";
Moment.locale("zh-CN");
export default {
  components: {
    [Field.name]: Field,
    [NoticeBar.name]: NoticeBar,
  },

  setup() {
    const stoppedByUser = ref(false);
    let timer1 = null; // 充电中订单的充电时长计数器
    let timer2 = null; // 循环加载计时器
    let timer3 = null; // 启动倒计时定时器
    let maxStartTime = 90; // 最大启动等待时间
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      loading: true,
      order_id: 0, // 当前订单ID
      from: "", // 来路页面, 当为 form 时表示从充电表单页面启动充电后进入本页面
      tips: "",
      now: Math.ceil(new Date().getTime() / 1000), // 系统当前的时间戳
      disableStopCharging: false, // 用于控制停止按钮不可重复点击 和 点击停止后页面时间不再计数量
      showDrawGunTips: false, // 提示拔枪
    });

    let toast1 = null;
    const loadForm = async (loop = 0) => {
      try {
        const { result } = await getChargingOrderDetail({
          order_id: state.order_id,
          loop,
        });
        for (const key in result) {
          state[key] = result[key];
        }
        clearInterval(timer1);
        clearInterval(timer2);

        // 充电中需每秒递增充电时间
        if (
          !state.disableStopCharging &&
          (state.order_status == 0 || state.charging_status == 2)
        ) {
          timer1 = setInterval(() => {
            state.now = Math.ceil(new Date().getTime() / 1000);
          }, 1000);
        }

        // 充电中 或 手动停止充电后但order_status还是充电中 的情况下 需每5秒刷新订单数据
        if (
          state.order_status == 0 ||
          state.charging_status == 2 || // 充电中
          (state.order_status == 1 && state.charging_status == 3) || // 手动停止充电
          (state.order_status == 2 && state.end_code === null) // 已停止但未拔枪
        ) {
          timer2 = setInterval(() => {
            loadForm(1);
          }, 5000);
        }

        // 订单已完成的情况下, 不再需要提示拔枪, 这个时候用户已经拔枪了
        if (state.charging_status == 3) {
          state.showDrawGunTips = false;
        }

        // 当由充电表单页面开启充电后, 跳转过来本页面时时, 需要手动关闭 Toast
        // 用户如果手动结束充电, 则不进行以下流程, 否则会影响提示信息
        if (state.from == "form" && !stoppedByUser.value) {
          if (state.charging_status < 2) {
            // 启动中, 倒计时
            if (!timer3) {
              toast1 = Toast.loading({
                message: `启动中 ${maxStartTime} 秒`,
                forbidClick: true,
                duration: 0,
              });
              timer3 = setInterval(() => {
                maxStartTime--;
                if (maxStartTime) {
                  toast1.message = `启动中 ${maxStartTime} 秒`;
                } else {
                  clearInterval(timer3);
                  toast1.clear();
                  toast1 = null;
                }
              }, 1000);
            }
          } else if (state.charging_status == 2) {
            state.charging_msg = `恭喜，启动成功，耗时 ${
              90 - maxStartTime
            } 秒！`;
            clearInterval(timer3);
            if (toast1) {
              toast1.clear();
              toast1 = null;
            }
          } else {
            clearInterval(timer1);
            clearInterval(timer2);
            clearInterval(timer3);
            Toast.fail(state.charging_msg);
          }
        }

        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
        setTimeout(() => {
          router.back();
        }, 2000);
      }
    };

    const gunName = computed(() => {
      const names = ["主枪", "A枪", "B枪"];
      if (state.gun_number < 0 || state.gun_number > 2) {
        return "未知";
      }
      return names[state.gun_number];
    });

    const chargingTime = computed(() => {
      if (state.order_status == 1 && state.charging_status == 2) {
        const difftime = state.now - state.charging_started_at;
        const isotime = Moment.duration(difftime, "seconds").toISOString();
        return isotime
          .replace(/(P|T)?/gi, "")
          .replace("D", "天")
          .replace("H", "时")
          .replace("M", "分")
          .replace("S", "秒");
      }
      if (state.order_status == 2 || state.order_status == 3) {
        const isotime = Moment.duration(
          state.actual_charging_time,
          "seconds"
        ).toISOString();
        return isotime
          .replace(/(P|T)?/gi, "")
          .replace("D", "天")
          .replace("H", "时")
          .replace("M", "分")
          .replace("S", "秒");
      }
      return "/";
    });

    const totalPower = computed(() => {
      const power = new Number(state.total_power * 0.01).toFixed(2);
      return `${power}kW·h`;
    });

    const totalAmount = computed(() => {
      const amount = new Number(state.total_amount * 0.01).toFixed(2);
      return `${amount}元`;
    });

    const chargingStatus = computed(() => {
      if (
        state.order_status < 0 ||
        state.order_status > 4 ||
        state.charging_status < 0 ||
        state.charging_status > 8
      ) {
        return "未知";
      }
      const workStatus = [
        "空闲中",
        "准备中",
        "充电中",
        "已结束",
        "启动失败",
        "已预约",
        "系统故障",
        "被占用",
        "升级中",
      ];
      const orderStatus = [
        "启动中", // 已创建
        "充电中",
        "已停止",
        "已完成",
        "已关闭",
      ];
      if (state.order_status == 0 && state.charging_status > 3) {
        return workStatus[state.charging_status];
      }
      if (state.order_status == 1) {
        return workStatus[state.charging_status];
      } else {
        return orderStatus[state.order_status];
      }
    });

    const formatDateTime = (time) => {
      return Moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
    };

    const createdAt = computed(() => {
      if (state.created_at) {
        return formatDateTime(state.created_at);
      }
      return "/";
    });

    const startedAt = computed(() => {
      if (state.charging_started_at) {
        return formatDateTime(state.charging_started_at);
      }
      return "/";
    });

    const stoppedAt = computed(() => {
      if (state.charging_stopped_at) {
        return formatDateTime(state.charging_stopped_at);
      }
      return "/";
    });

    const stopCharging = () => {
      Dialog.confirm({
        title: "结束充电",
        message: "请确认是否结束充电?",
      })
        .then(() => {
          processStop();
        })
        .catch(() => {});
    };

    const processStop = async () => {
      state.disableStopCharging = true;
      if (state.from != "form") {
        Toast.loading({
          message: "正在结束充电",
          duration: 0,
          forbidClick: true,
        });
      }
      try {
        state.showDrawGunTips = true;
        stoppedByUser.value = true;
        await stopPowerRecharge(state.order_id);
        clearInterval(timer1);
        clearInterval(timer2);
        state.tips = "您已成功停止充电";
        setTimeout(() => {
          loadForm();
          Toast.clear();
        }, 1000);
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { order_id, from } = route.query;
      if (!order_id) {
        Toast.fail("参数错误");
        setTimeout(() => {
          router.back();
        }, 2000);
      } else {
        state.order_id = order_id;
        state.from = from;
        loadForm();
      }
    });

    onUnmounted(() => {
      clearInterval(timer1);
      clearInterval(timer2);
    });

    return {
      state,
      loadForm,
      gunName,
      chargingTime,
      totalPower,
      totalAmount,
      chargingStatus,
      createdAt,
      startedAt,
      stoppedAt,
      stopCharging,
    };
  },
};
</script>
